import {
  Badge,
  Button,
  Card,
  Divider,
  Grid,
  Group,
  SimpleGrid,
  Stack,
  Table,
  Text,
  Image,
  Flex,
  Box,
  Modal,
  NumberInput,
  Select,
  Container,
  LoadingOverlay,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { StudentFeesCards } from "../../components/AdminPage/DashBoard/DashBoardCards";
import { useMediaQuery } from "@mantine/hooks";
import { DatePicker } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons";
import FeeRecordTable from "./FeeRecordTable";
import { UpdateMultipleFeeRecord } from "../../features/StudentSlice";
import { showNotification } from "@mantine/notifications";

const FeeRecordSection = (props: {
  userType: string;
  feeRecords: FeeRecord[];
  dateOfJoining: Date;
  batch?: string;
  studentId: string;
  onPaymentClick: () => void;
}) => {
  const [assignedBatch, setAssignedBatch] = useState<Map<string, string>>(
    new Map()
  );
  const [feeRecords, setFeeRecords] = useState<FeeRecord[]>(props.feeRecords);

  const totalFees = props.feeRecords?.reduce(
    (sum: number, record: FeeRecord) => sum + record.totalAmount,
    0
  );
  const totalPaidFees = props.feeRecords?.reduce(
    (sum: number, record: FeeRecord) => sum + record.amountPaid,
    0
  );
  const totalOverdue = totalFees - totalPaidFees;

  const [openPaymentModel, setOpenPaymentModel] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>({
    paymentDate: new Date(),
  });

  const [feeRecordsMap, setFeeRecordsMap] = useState<
    Map<string, FeeRecordData>
  >(new Map());

  const [batchWiseFeeRecords, setBatchWiseFeeRecords] = useState<FeeRecord[][]>(
    []
  );
  const [isLoading, setisLoading] = useState<boolean>(false);

  const groupFeeRecords = (records: FeeRecord[]) => {
    const groupFeeData: { [key: string]: FeeRecord[] } = {};
    records.forEach((record: FeeRecord) => {
      const { _id } = record?.batch;

      if (!groupFeeData[_id]) {
        groupFeeData[_id] = [];
      }
      groupFeeData[_id].push(record);
    });

    return Object.values(groupFeeData);
  };


  useEffect(() => {
    const groupData = groupFeeRecords(props.feeRecords);
    setBatchWiseFeeRecords(groupData);
    const newMap = new Map(assignedBatch);
    props.feeRecords.forEach((item) => {
      if (!newMap.has(item.batch._id)) {
        newMap.set(item.batch._id, item.batch.name);
      }
    });
    setAssignedBatch(newMap);
  }, [props.feeRecords]);

  const handleChange = (key: string, value: any) => {
    if (key === "paymentDate") {
      setFormValues((prev) => ({ ...prev, paymentDate: value }));
      setFeeRecordsMap((prevMap) => {
        const newMap = new Map(prevMap);
        newMap.forEach((record, recordId) => {
          newMap.set(recordId, { ...record, paidDate: value });
        });
        return newMap;
      });
    } else {
      setFeeRecordsMap((prevMap) => {
        const newMap = new Map(prevMap);
        const existingRecord = newMap.get(key) || {
          amount: 0,
          paidDate: formValues.paymentDate,
        };
        newMap.set(key, { ...existingRecord, amount: value });
        return newMap;
      });
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    if(!formValues.paymentDate){
      showNotification({
        message: "Select date please!!",
      });
      return
    }
    setisLoading(true);

    UpdateMultipleFeeRecord({ formData: formValues, feeRecords: feeRecordsMap })
      .then((resp) => {
        setisLoading(false);
        setOpenPaymentModel(false);
        setFeeRecordsMap(new Map());
        props.onPaymentClick();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const isMd = useMediaQuery(`(max-width: 968px)`);

  const [selectedBatchId, setSelectedBatchId] = useState<string | null>(null);

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Stack sx={{ backgroundColor: "#ffffff" }}>
        <Grid>
          <Grid.Col span={isMd ? 12 : 10}>
            <SimpleGrid
              cols={isMd ? 2 : 4}
              spacing={isMd ? 15 : 40}
              verticalSpacing={20}
            >
              <StudentFeesCards
                totalFees={totalFees}
                totalPaid={totalPaidFees}
                totalOverdue={totalOverdue}
              />
            </SimpleGrid>
          </Grid.Col>
        </Grid>
        <Card shadow="sm">
          <Flex justify={"space-between"} align={"center"}>
            <Text size="sm" color="blue">
              Fee Records
            </Text>
            {props.userType === "teacher" ? (
              <Button
                onClick={() => {
                  if (totalOverdue <= 0) {
                    showNotification({
                      message: "No Pending Payment ",
                    });
                    return;
                  }
                  setOpenPaymentModel(true);
                }}
              >
                Record Payment
              </Button>
            ) : (
              ""
            )}
          </Flex>
          <Modal
            opened={openPaymentModel}
            onClose={() => setOpenPaymentModel(false)}
            title="Record Payment"
            centered
            size="sm"
            zIndex={999}
            styles={{
              title: {
                fontSize: 20,
                fontWeight: 700,
              },
            }}
          >
            <Container sx={{ width: "100%" }}>
              <DatePicker
                label="Payment Date"
                required
                placeholder="Select date"
                icon={<IconCalendar size={16} />}
                value={formValues.paymentDate}
                onChange={(date) => handleChange("paymentDate", date)}
              />
              <Select
                w={"12rem"}
                my={"md"}
                label="Select Batch"
                placeholder="Select Batch"
                data={Array.from(assignedBatch.entries()).map(
                  ([key, value]) => ({
                    value: key,
                    label: value,
                  })
                )}
                value={selectedBatchId}
                rightSection={
                  <Image
                    src={require("../../assets/arrow-down.png")}
                    alt="message"
                    style={{ width: 20, marginRight: "4px" }}
                  />
                }
                onChange={(value: string) => setSelectedBatchId(value)}
              />

              <Divider my="md" />

              {props.feeRecords
                .filter((record) => record.batch?._id === selectedBatchId)
                .map((record: FeeRecord) => {
                  return (
                    <Flex key={record?._id} justify={"start"} align={"center"}>
                      <NumberInput
                        label={record.name}
                        value={feeRecordsMap.get(record._id)?.amount || 0}
                        onChange={(value) => {
                          handleChange(record._id, value || 0);
                        }}
                        max={record.totalAmount - record.amountPaid}
                        min={0}
                      />
                      <Text fw={700} fz="md" color="black">
                        / ₹{record.totalAmount - record.amountPaid + " "}
                        <span style={{ fontSize: "10px", color: "gray" }}>
                          (Pending)
                        </span>
                      </Text>
                    </Flex>
                  );
                })}

              <Group position="right" mt="md">
                <Button
                  onClick={() => setOpenPaymentModel(false)}
                  radius={10}
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button radius={10} onClick={handleSubmit} type="submit">
                  Payment
                </Button>
              </Group>
            </Container>
          </Modal>
          <Divider my="sm" />
          <Flex w={"100%"} justify="space-between" align="center">
            <Text size="md" fw={700} color="black">
              Batches
            </Text>
            <Flex w={"50%"} justify="space-between" align="center">
              <Text size="md" fw={700} color="black">
                Fees
              </Text>
              <Text size="md" fw={700} color="black">
                Pending
              </Text>
              <Text></Text>
            </Flex>
          </Flex>
          <Divider my="sm" />
          {batchWiseFeeRecords?.map((feeRecords) => (
            <FeeRecordTable
              data={feeRecords}
              dateOfJoining={props.dateOfJoining}
              studentId={props.studentId}
              userType={props.userType}
            />
          ))}
        </Card>
      </Stack>
    </>
  );
};

export default FeeRecordSection;
