import ApiHelper from "../utilities/ApiHelper";

export function fetchStudentInfoById(params: { id: string }) {
  return new Promise((resolve, reject) => {
    ApiHelper.get(`/api/v1/instituteStudent/student/${params.id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
export function GetAllStudentsByClassId(data: { id: string }) {
  return new Promise((resolve, reject) => {
    ApiHelper.get(`/api/v1/instituteClass/getAllInstituteStudents/${data.id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
export function CreateStudent(data: StudentsDataWithBatch) {
  return new Promise((resolve, reject) => {
    ApiHelper.post(`/api/v1/instituteStudent/create`, {
      name: data.name,
      phoneNumber: data.phoneNumber,
      instituteId: data.instituteId,
      instituteBatchId: data.batchId,
      parentName: data.parentName,
      parentNumber: data.parentNumber,
      dateOfBirth: data.dateOfBirth,
      address: data.address,
      gender: data.gender,
      standard: data.standard,
      source: data.source,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export async function CreateMultipleStudent(data: {
  students: StudentsDataWithBatch[];
}) {
  const promises = data.students.map((x) => {
    return CreateStudent({
      name: x.name,
      phoneNumber: x.phoneNumber,
      instituteId: x.instituteId,
      parentName: x.parentName,
      parentNumber: x.parentNumber,
      dateOfBirth: x.dateOfBirth,
      address: x.address,
      gender: x.gender,
      standard: x.standard,
      source: x.source,
    });
  });
  try {
    return await Promise.all(promises);
  } catch (error) {
    throw error;
  }
}

export function AddBatchesIdsAndCourseIdsIntoStudent(data: {
  studentId: string;
  batchIds: string[];
  dateOfJoining: string;
}) {
  return new Promise((resolve, reject) => {
    ApiHelper.put(`/api/v1/instituteStudent/addBatchIdsAndCourseIds`, {
      studentId: data.studentId,
      batchIds: data.batchIds,
      dateOfJoining: data.dateOfJoining,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export function AddStudentFeeRecords(data: {
  studentId: string;
  type: string;
  installments: Map<string, Installment[]>;
}) {
  const transformedInstallments = Array.from(
    data.installments.entries()
  ).flatMap(([batch, installments]) =>
    installments.map((installment) => ({
      ...installment,
      batch,
      type: data.type,
      student: data.studentId,
    }))
  );
  return new Promise((resolve, reject) => {
    ApiHelper.post(`/api/v1/feeRecord/createFeeRecord`, {
      installments: transformedInstallments,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export const updateFeeRecord = async (data: {
  id: string;
  installment: { amount: number; paidDate: string };
}) => {
  try {
    return new Promise((resolve, reject) => {
      ApiHelper.put(`/api/v1/feeRecord/updateFeeRecord/${data.id}`, {
        installment: data.installment,
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  } catch (error) {
    throw error;
  }
};

export async function UpdateMultipleFeeRecord(data: {
  formData: FormValues;
  feeRecords: Map<string, FeeRecordData>;
}) {
  try {
    const promises: any = Array.from(data.feeRecords.entries()).filter(
      ([id, recordData]) => {
        if (recordData.amount !== 0) {
          return updateFeeRecord({
            id,
            installment: {
              amount: recordData.amount,
              paidDate: recordData.paidDate.toString() ?? new Date().toString(),
            },
          });
        }
      }
    );
    return await Promise.all(promises);
  } catch (error) {
    throw error;
  }
}

export async function UpdateStudent(data: {
  id: string;
  formData: StudentsDataWithBatch;
  courseInfo: {
    batchIds: string[];
    dateOfJoining?: Date;
  };
  feeRecords: {
    customOrBatch: Map<string, string>;
    batchId: string;
    installments: Map<string, Installment[]>;
  };
}) {
  try {
    let transformedInstallments: any[] = [];
    if (data.feeRecords && data.feeRecords.installments instanceof Map) {
      const installmentsArray = Array.from(
        data.feeRecords.installments.entries()
      );
      installmentsArray.forEach(([batchId, installments]) => {
        const transformed = installments.map((installment: any) => ({
          ...installment,
          batch: batchId,
          type: data.feeRecords?.customOrBatch.get(batchId),
          student: data.id,
          dueDate: installment.dueDate
            ? new Date(installment.dueDate)
            : new Date(),
        }));
        transformedInstallments.push(...transformed);
      });
    }

    return new Promise((resolve, reject) => {
      ApiHelper.put(`/api/v1/instituteStudent/updateStudent/${data.id}`, {
        formData: data.formData,
        courseInfo: data.courseInfo,
        feeRecords: transformedInstallments,
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  } catch (error) {
    throw error;
  }
}

export async function EditStudentProfileFromStudent(
  id: string,
  data: StudentEditProfile
) {
  return new Promise((resolve, reject) => {
    ApiHelper.put(
      `/api/v1/instituteStudent/updateGenderAndStandardFromStudent/${id}`,
      {
        gender: data.gender,
        standard: data.standard,
      }
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export async function EditStudentProfileSingleFieldFromStudent(data: {
  id: string;
  [key: string]: string | undefined;
}) {
  const { id, ...fields } = data;
  const sanitizedFields = Object.fromEntries(
    Object.entries(fields).map(([key, value]) => [key, value === undefined ? null : value])
  );

  console.log("sanitizedFields: ", sanitizedFields);
  return new Promise((resolve, reject) => {
    ApiHelper.put(
      `/api/v1/instituteStudent/editStudentProfileSingleFieldFromStudent/${id}`,
      sanitizedFields
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
